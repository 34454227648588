<template>
  <v-container>
    <v-row class="mb-8 ml-2">
      <v-col cols="12" md="2" lg="2">
        <label class="label-input">{{ $t("ot_report.year") }}</label>
        <v-select
          :items="years"
          outlined
          dense
          v-model="year"
          @change="fetchEmployeeHourTotal"
        ></v-select>
      </v-col>
      <v-col cols="12" md="2" lg="2">
        <label class="label-input">{{ $t("ot_report.month") }}</label>
        <v-select
          :items="months"
          outlined
          dense
          v-model="month"
          @change="fetchEmployeeHourTotal"
        ></v-select>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="4" lg="4" sm="12" class="mb-4">
        <v-card max-width="400" class="mx-auto overflow-hidden">
          <div class="bg-head">
            <div class="pa-4 ml-6">
              <v-icon large color="white darken-2">
                mdi-alarm
              </v-icon>

              <span class="text ml-4 lfont">OT ທັງໝົດ</span>
            </div>
          </div>

          <v-card-text>
            <div class="text text--primary lfont">ເຮັດວຽກຕົວຈິງ</div>
            <p class="text-h4 text--primary">
              <span class="lfont" v-if="qty_working.days > 0">
                {{ qty_working.days }}
                {{ $t("ot_report.day") }}
              </span>
              <span class="lfont" v-if="qty_working.hours > 0">
                {{ qty_working.hours }}
                {{ $t("ot_report.hour") }}
              </span>
              <span class="lfont" v-if="qty_working.minutes > 0">
                {{ qty_working.minutes }}
                {{ $t("ot_report.minute") }}
              </span>
            </p>
            <v-divider></v-divider>
            <div class="text text--primary lfont">ໄດ້ຮັບ</div>
            <p class="text-h4 text--primary">
              <span class="lfont" v-if="qty_receive.days > 0">
                {{ qty_receive.days }}
                {{ $t("ot_report.day") }}
              </span>
              <span class="lfont" v-if="qty_receive.hours > 0">
                {{ qty_receive.hours }}
                {{ $t("ot_report.hour") }}
              </span>
              <span class="lfont" v-if="qty_receive.minutes > 0">
                {{ qty_receive.minutes }}
                {{ $t("ot_report.minute") }}
              </span>
            </p>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" md="4" lg="4" sm="12" class="mb-4">
        <v-card max-width="400" class="mx-auto overflow-hidden">
          <div class="bg-head1">
            <div class="pa-4 ml-6">
              <v-icon large color="white darken-2">
                mdi-history
              </v-icon>

              <span class="text ml-4 lfont">ໃຊ້ແລ້ວ</span>
            </div>
          </div>
          <v-card-text>
            <div class="text text--primary lfont">ໃຊ້ແລ້ວ</div>
            <p class="text-h4 text--primary">
              <span class="lfont" v-if="qty_using.days > 0">
                {{ qty_using.days }}
                {{ $t("ot_report.day") }}
              </span>
              <span class="lfont" v-if="qty_using.hours > 0">
                {{ qty_using.hours }}
                {{ $t("ot_report.hour") }}
              </span>
              <span class="lfont" v-if="qty_using.minutes > 0">
                {{ qty_using.minutes }}
                {{ $t("ot_report.minute") }}
              </span>
            </p>
          </v-card-text>
          <!-- <v-card-actions>
            <v-btn outlined color="green" class="lfont ma-2">
              ເບິ່ງລາຍລະອຽດ
            </v-btn>
          </v-card-actions> -->
        </v-card>
      </v-col>
      <v-col cols="12" md="4" lg="4" sm="12" class="mb-4">
        <v-card max-width="400" class="mx-auto overflow-hidden">
          <div class="bg-head2">
            <div class="pa-4 ml-6">
              <v-icon large color="white darken-2">
                mdi-history
              </v-icon>

              <span class="text ml-4 lfont">ຍັງເຫຼືອ</span>
            </div>
          </div>
          <v-card-text>
            <div class="text text--primary lfont">ຍັງເຫຼືອ</div>
            <p class="text-h4 text--primary">
              <span class="lfont" v-if="qty_remaining.days > 0">
                {{ qty_remaining.days }}
                {{ $t("ot_report.day") }}
              </span>
              <span class="lfont" v-if="qty_remaining.hours > 0">
                {{ qty_remaining.hours }}
                {{ $t("ot_report.hour") }}
              </span>
              <span class="lfont" v-if="qty_remaining.minutes > 0">
                {{ qty_remaining.minutes }}
                {{ $t("ot_report.minute") }}
              </span>
            </p>
          </v-card-text>
          <!-- <v-card-actions>
            <v-btn outlined color="orange" class="lfont ma-2">
              ເບິ່ງລາຍລະອຽດ
            </v-btn>
          </v-card-actions> -->
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import moment from "moment";
import defaultTableNoResult from "@/components/defaultTableNoResult";
import Loading from "@/components/Loading";
export default {
  components: {
    defaultTableNoResult,
    Loading,
    DatePicker,
  },
  data() {
    return {
      year: new Date().getFullYear(),
      month: "",
      months: [],
      isLoading: true,
      qty_receive: {},
      qty_working: {},
      qty_using: {},
      qty_request: {},
      qty_remaining: {},
      searchItem: "",
      isLoading: true,
      // month_year: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
      //   .toISOString()
      //   .substr(0, 10),
    };
  },
  methods: {
    getYears() {
      let year = this.year - 4;
      let years = [];
      for (let i = 5; i > 1; i--) {
        years.push(year + i);
      }
      this.years = years;
    },
    searchFilterItem() {
      this.fetchEmployeeHourTotal();
      this.pagination.current_page = 1;
    },

    fetchEmployeeHourTotal() {
      // let month = moment(this.month_year).format("MM");
      // let year = moment(this.month_year).format("YYYY");
      this.isLoading = true;
      this.$axios
        .get(`employee/conclude/ot`, {
          params: { month: this.month, year: this.year },
        })
        .then((res) => {
          console.log(res);
          if (res.status === 200) {
            this.isLoading = false;
            this.qty_receive = res.data.data.qty_receive;
            this.qty_working = res.data.data.qty_working;
            this.qty_using = res.data.data.qty_using;
            this.qty_request = res.data.data.qty_request;
            this.qty_remaining = res.data.data.qty_remaining;
            // if (!this.EmployeeHourTotal.length > 0) {
            //   this.pagination.current_page = this.pagination.current_page - 1;
            // }
          }
          this.isLoading = false;
        })
        .catch((error) => {
          console.log(error);
          this.isLoading = false;
        });
    },
  },
  created() {
    this.fetchEmployeeHourTotal();
    this.getYears();
  },
};
</script>

<style lang="scss" scoped>
.bg-head {
  background-color: #03a9f4 !important;
}
.bg-head1 {
  background-color: #00c853 !important;
}
.bg-head2 {
  background-color: #fb8c00 !important;
}
.text {
  color: white !important;
}
</style>
